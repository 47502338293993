<template>
    <div class="container pb-12 lg:pb-24">
        <div
            class="flex flex-col lg:flex-row gap-8 xl:gap-16 pb-8 lg:pb-16 mb-5 border-b border-solid border-[#EAEAEA]"
        >
            <div class="w-[280px]">
                <div class="lg:sticky top-30">
                    <div class="mb-5 text-blacks-100 text-sm font-semibold">Chuyên mục</div>
                    <div class="flex flex-col gap-1">
                        <nuxt-link
                            to="/tin-tuc/"
                            class="px-3.5 py-2.5 text-base leading-[1.5] font-semibold text-blacks-30 rounded-6px"
                            :class="!currentCategory ? 'bg-[#F3F3F3] !text-blacks-100' : ''"
                            >Tất cả</nuxt-link
                        >
                        <nuxt-link
                            v-for="item in dataCategories"
                            :to="`/tin-tuc?category=${item?.slug}`"
                            class="px-3.5 py-2.5 text-base leading-[1.5] font-semibold text-blacks-30 rounded-6px"
                            :class="currentCategory === item?.slug ? 'bg-[#F3F3F3] !text-blacks-100' : ''"
                            >{{ item?.title }}</nuxt-link
                        >
                    </div>
                </div>
            </div>
            <div class="flex-1">
                <div v-if="dataNews?.news && dataNews?.news.length > 0" class="hidden lg:grid grid-cols-3 mb-12">
                    <div class="col-span-2 mr-8 lg:h-[200px] xl:h-[320px] overflow-hidden group">
                        <NuxtImg
                            :src="getThumbnail(dataNews?.news[0]?.thumbnail, 560, true)"
                            width="560"
                            sizes="sm:100vw md:50vw lg:560px"
                            loading="lazy"
                            :alt="dataNews?.title"
                            :placeholder="[50, 25, 75, 5]"
                            class="w-full h-full object-cover transform duration-250 group-hover:scale-115"
                        />
                    </div>
                    <div class="col-span-1">
                        <div class="text-blacks-70 font-semibold text-base">
                            {{ dataNews?.news[0]?.category?.title }}
                        </div>
                        <nuxt-link
                            :to="dataNews?.news[0]?.url"
                            class="block my-3 text-xl xl:text-3xl leading-[126.667%] font-semibold text-blacks-100 hover:underline duration-250 !line-clamp-3"
                            >{{ dataNews?.news[0]?.title }}
                        </nuxt-link>
                        <div class="text-base text-blacks-90 mb-6 !line-clamp-4">{{ dataNews?.news[0]?.intro }}</div>
                        <div class="text-blacks-90 text-sm">{{ formatDate(dataNews?.news[0]?.date_created) }}</div>
                    </div>
                </div>
                <p v-else class="text-xl text-gray-500">Không có mục tin tức nào</p>
                <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-8 lg:gap-y-12">
                    <div
                        v-for="(item, index) in dataNews?.news"
                        class="flex flex-col group"
                        :class="index === 0 ? 'lg:hidden' : ''"
                    >
                        <div class="h-[240px] w-full mb-6 overflow-hidden">
                            <NuxtImg
                                :src="getThumbnail(item?.thumbnail, 630, true)"
                                width="630"
                                sizes="sm:100vw md:50vw lg:630px "
                                loading="lazy"
                                :alt="item?.title"
                                :placeholder="[50, 25, 75, 5]"
                                class="w-full h-full object-cover transform duration-250 group-hover:scale-115"
                            />
                        </div>
                        <div class="text-blacks-70 text-sm font-semibold">{{ item?.category?.title }}</div>
                        <div class="flex-1 flex flex-col">
                            <nuxt-link :to="item?.url" class="flex gap-4 my-2">
                                <div
                                    class="max-w-[380px] text-blacks-100 text-base lg:text-2xl font-semibold hover:underline duration-250 line-clamp-2"
                                >
                                    {{ item?.title }}
                                </div>
                                <span class="i-ic:baseline-arrow-outward min-w-6 min-h-6"></span>
                            </nuxt-link>
                            <div class="text-sm lg:text-base text-blacks-90 mb-3 lg:mb-6">
                                {{ item?.intro }}
                            </div>
                        </div>
                        <div class="text-blacks-90 text-sm">{{ formatDate(item?.date_created) }}</div>
                    </div>
                </div>
            </div>
        </div>
        <global-pagination v-if="dataNews?.total_page > 1" :pages="dataNews?.total_page" :current-page="currentPage" />
    </div>
</template>
<script setup lang="ts">
const route = useRoute()
const { getNewsByCategory, getNewsCategories } = useNews()

const currentCategory = ref('')
const currentPage = ref(1)
const dataCategories = ref()
const dataNews = ref()
const getDataNews = async (category: any, page: any) => {
    const resNews: any = await Promise.all([
        getNewsCategories(),
        getNewsByCategory({ slug: category, query: { limit: 9, page: page } })
    ])
        .then((data) => data)
        .catch((e) => {
            console.log(e)
        })
    dataCategories.value = resNews[0]
    dataNews.value = resNews[1]
}
await getDataNews('', route?.query?.page || 1)
watch(
    () => route?.query,
    async () => {
        currentCategory.value = route?.query?.category || ''

        currentPage.value = route?.query?.page || 1
        await getDataNews(currentCategory.value, currentPage.value)
    }
)
</script>
