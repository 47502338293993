<template>
    <div class="py-12 lg:pt-24 lg:pb-33">
        <div class="container">
            <global-breadcrumb :breadcrumb="breadcrumb" isLight/>
            <h2 class="mt-3 text-2xl sm:text-35px lg:text-5xl leading-[125%] text-blacks-100 font-semibold tracking-[-0.96px]">
                <v-text option_key="news_banner_title" />
            </h2>
            <div class="max-w-[768px] mt-3 lg:mt-6 text-base lg:text-xl leading-[1.5] text-blacks-70">
                <v-text option_key="news_banner_text" />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
const breadcrumb = ref([
    {
        url: '/',
        title: 'Trang chủ'
    },
    {
        url: '/tin-tuc',
        title: 'Tin tức'
    },
])
</script>