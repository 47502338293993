<template>
    <div class="bg-white">
        <hero-section />
        <list-news />
    </div>
</template>

<script setup lang="ts">
import HeroSection from './hero-section.vue'
import ListNews from './list-news.vue'
useDirectusCollectionSeo('news_index', {})
</script>

<style></style>
